/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.em, null, "The Series A round was led by Five Elms Capital and will support further investment in Prismatic's embedded integration platform")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "SAN FRANCISCO, February 21, 2023:"), " ", React.createElement(_components.a, {
    href: "https://prismatic.io/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Prismatic"), ", a leading embedded integration platform, today announced that it has raised $9 million in a Series A round led by ", React.createElement(_components.a, {
    href: "https://www.fiveelms.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Five Elms Capital"), ". The funding will support further investment in Prismatic's platform, which helps B2B SaaS companies more efficiently deliver native product integrations to the other apps their customers use."), "\n", React.createElement(_components.p, null, "Prismatic's embedded integration platform (embedded iPaaS) is one of the quickest ways for B2B SaaS companies to cost-effectively build, deploy, and support better native integrations for their users. The platform has been eagerly adopted by SaaS product and engineering teams ranging from startup to Fortune 100 across many software verticals."), "\n", React.createElement(_components.p, null, "\"B2B SaaS companies face increasing demand to deliver high-quality product integrations to their customers' growing tech stacks. This trend highlights the growing expense of building these integrations and the need for better tools to streamline that process,\" said Austin Gideon, Principal at Five Elms Capital. \"Prismatic has developed an incredibly valuable solution that takes the process from months per integration to days or weeks at a fraction of the cost. It has been very well received by the market, and it's clear Prismatic is poised for rapid expansion as a leader in this space.\""), "\n", React.createElement(_components.p, null, "This announcement follows Prismatic's ", React.createElement(_components.a, {
    href: "../winter-2023-g2-report-embedded-integration-platforms/"
  }, "recent recognition"), " by G2.com as the #1 embedded integration platform by customer satisfaction score. It also earned Leader, Best Meets Requirements, Easiest Setup, and Best Relationship awards."), "\n", React.createElement(_components.p, null, "\"Prismatic combines an embeddable integration marketplace with a low-code builder and sophisticated dev tools that give SaaS teams the flexibility to build any integration their customers need,\" said Prismatic CEO and Cofounder Michael Zuercher. \"This has proven to be a winning solution, and we're excited to partner with an investor who shares our vision of delivering the most dev-friendly, extensible embedded iPaaS that's capable of handling even the most complex integrations.\""), "\n", React.createElement(_components.p, null, "The new funding is also being used to scale Prismatic's team to meet increasing demand for its platform. To view current job opportunities, visit ", React.createElement(_components.a, {
    href: "/careers/"
  }, "prismatic.io/careers"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "About Prismatic")), "\n", React.createElement(_components.p, null, "Prismatic is the integration platform for B2B software companies. It's the quickest way to build integrations to the other apps your customers use and to add a native integration marketplace to your product. A complete embedded iPaaS solution that empowers your whole organization, Prismatic encompasses an intuitive integration designer, embedded integration marketplace, integration deployment and support, and a purpose-built cloud infrastructure. Prismatic was built in a way developers love and provides the tools to make it perfectly fit the way you build software. To learn more, visit ", React.createElement(_components.a, {
    href: "https://prismatic.io/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "prismatic.io"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "About Five Elms")), "\n", React.createElement(_components.p, null, "Five Elms Capital is a leading growth investor in world-class software businesses that users love. Five Elms provides capital and resources to help companies accelerate growth and further cement their role as industry leaders. Five Elms maintains offices in North America and Europe and invests in the best software platforms globally. For more information, visit ", React.createElement(_components.a, {
    href: "https://www.fiveelms.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "fiveelms.com"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
